import ODataMixin from '@/mixins/ODataMixin'
import FilterModel from '@/models/FilterModel'
import IODataQuery from '@/models/IODataQuery'
import { IODataRequest } from '@/models/IODataRequest'
import ODataResult from '@/models/ODataResult'
import { PropOptions } from 'vue/types/umd'
import IFlatSourceData from '../models/IFlatSourceData'
import IMapReleaseRequest from '../models/IMapReleaseRequest'

export default ODataMixin.extend({
  props: {
    filters: {
      type: [Array],
      default: () => {
        return [
          new FilterModel('All', {
            filter: 'isRemoved eq false'
          }),
          new FilterModel('Mapped', {
            filter: 'ignoreMapping eq false and isMapped eq true and isRemoved eq false'
          }),
          new FilterModel('Unmapped', {
            filter: 'ignoreMapping eq false and isMapped eq false and isRemoved eq false'
          }),
          new FilterModel('Ignored', {
            filter: 'ignoreMapping eq true and isRemoved eq false'
          }),
          new FilterModel('Errors', {
            filter: 'ignoreMapping eq false and isRemoved eq false and errors/any()'
          }),
          new FilterModel('Archived', {
            filter: 'isRemoved eq true'
          })
        ]
      }
    } as PropOptions<FilterModel[]>
  },
  data() {
    return {
      sourceData: null as ODataResult<IFlatSourceData>
    }
  },
  methods: {
    async refresh() {
      const table = this.$refs.table as any
      if (table) {
        await table.getItems()
      }
      await this.getFilterCounts()
    },
    async getSourceData(query: IODataQuery) {
      this.sourceData = await this.get('SourceData/Flat()', query)
    },
    async mapToRelease(data: IMapReleaseRequest) {
      data.newRelease ? await this.mapToNewRelease(data) : await this.mapToExistingRelease(data)
      await this.refresh()
    },
    async mapToExistingRelease(data: IMapReleaseRequest) {
      const request: IODataRequest = {
        url: `SourceData(${data.dataSourceId})/MapToExistingRelease`,
        method: 'POST',
        data: {
          listingReleaseId: data.releaseId
        },
        headers: {
          'If-Match': data.concurrency
        }
      }

      if (data.editionId) {
        request.data.editionId = data.editionId
      }

      await this.request(request)
    },
    async mapToNewRelease(data: IMapReleaseRequest) {
      const request: IODataRequest = {
        url: `SourceData(${data.dataSourceId})/MapToNewRelease`,
        method: 'POST',
        data: {
          listingId: data.listingId,
          listingReleaseName: data.releaseName
        },
        headers: {
          'If-Match': data.concurrency
        }
      }

      if (data.editionId) {
        request.data.editionId = data.editionId
      }

      await this.request(request)
    },
    async unmapRelease(dataSourceId: number, listingReleaseId: number) {
      await this.post(`SourceData(${dataSourceId})/Unmap`, { listingReleaseId })
      await this.refresh()
    },
    async toggleIgnoreMapping(sourceData: IFlatSourceData) {
      await this.patch(`SourceData(${sourceData.id})`, {
        ignoreMapping: !sourceData.ignoreMapping,
        comments: !sourceData.ignoreMapping ? sourceData.comments : null
      })
      await this.refresh()
    },
    async getFilterCounts() {
      const filters = this.filters
      const promises = filters.map((namedFilter) => {
        const query: IODataQuery = {
          $filter: namedFilter?.filter,
          $top: 0
        }
        return this.get('SourceData/Flat()', query)
      })
      const results = await Promise.all(promises)
      for (let i = 0; i < results.length; i++) {
        filters[i].count = results[i].count
      }
    }
  }
})
