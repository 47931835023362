











import ODataTable from '@/components/ODataTable.vue'
import IODataTableHeader from '@/models/IODataTableHeader'
import Vue from 'vue'

export default Vue.extend({
  components: {
    ODataTable
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  computed: {
    headers(): IODataTableHeader[] {
      return [
        {
          text: 'Modified',
          value: 'modifiedDate',
          format: 'datetime',
          width: 200
        },
        {
          text: 'Name',
          value: 'displayName'
        },
        {
          text: 'Source',
          value: 'dataSourceName'
        },
        {
          text: 'Policy',
          value: 'supportPolicyName'
        },
        {
          text: 'Start date',
          value: 'supportStartDate',
          format: 'date'
        },
        {
          text: 'Mainstream / Retirement',
          value: 'supportEndDate1',
          format: 'date'
        },
        {
          text: 'Extended End',
          value: 'supportEndDate2',
          format: 'date'
        },
        {
          text: 'PMM Lead',
          value: 'pmmlead'
        },
        {
          text: 'Product Owner',
          value: 'productOwner'
        },
        {
          text: 'Type',
          value: 'productType.name'
        },
        {
          text: 'Edition(s)',
          value: 'edition'
        }
      ]
    }
  }
})
