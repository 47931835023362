



















import Vue from 'vue'
import { RawLocation } from 'vue-router'
import IFlatSourceData from '../models/IFlatSourceData'

export default Vue.extend({
  props: {
    sourceDataItem: {
      type: Object as () => IFlatSourceData
    }
  },
  computed: {
    productName(): string {
      return this.sourceDataItem?.displayName
    },
    tooltip(): string {
      const productName = this.sourceDataItem?.productName
      const legalTitle = this.sourceDataItem?.legalTitle || 'N/A'
      return `Product Name:\n${productName}\n\nLegal Title:\n${legalTitle}`
    },
    links(): any[] {
      return this.sourceDataItem.listings.map((listing) => {
        const to: RawLocation = {
          name: 'Listing',
          params: {
            id: listing?.id.toString()
          }
        }
        return {
          to,
          text: listing?.name
        }
      })
    }
  }
})
