var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"source-data-table"},[_c('ODataTable',{ref:"table",attrs:{"url":_vm.url,"headers":_vm.headers,"expanded":_vm.expanded,"sort-by":['lastImportDate'],"sort-desc":[true],"hide-default-footer":_vm.dense,"filter":_vm.filter,"filters":_vm.dense ? undefined : _vm.filters,"items-per-page":_vm.dense ? 10 : undefined,"no-search":_vm.dense,"sync-url":_vm.syncUrl,"single-expand":"","must-sort":""},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.displayName",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"start","no-gutters":""}},[_c('v-col',[_c('ProductNameLink',{attrs:{"source-data-item":item},on:{"click":function($event){$event.stopPropagation();}}})],1),_c('v-col',{staticClass:"shrink"},[(item.errors.length || item.errorMessage)?_c('v-icon',{attrs:{"color":"warning","small":"","title":_vm.getErrorTooltip(item)}},[_vm._v("$warning")]):_vm._e()],1)],1)]}},{key:"item.supportStartDate",fn:function(ref){
var item = ref.item;
return [(item.supportStartDate)?_c('span',[_vm._v(_vm._s(_vm._f("date")(item.supportStartDate)))]):_c('span',{staticClass:"error--text"},[_vm._v("Missing")])]}},{key:"item.supportEndDate1",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":_vm.getTitle(item)}},[_vm._v(_vm._s(_vm._f("date")(item.supportEndDate1)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[(item.ignoreMapping && !item.isRemoved)?_c('v-btn',{attrs:{"icon":"","small":"","title":"Move to active"},on:{"click":function($event){$event.stopPropagation();return _vm.moveToActive(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$activate")])],1):[(!item.isRemoved)?_c('v-btn',{attrs:{"icon":"","small":"","title":"Create new listing"},on:{"click":function($event){$event.stopPropagation();return _vm.onCreateListing(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$add")])],1):_vm._e(),(!item.isRemoved)?_c('v-btn',{attrs:{"icon":"","small":"","title":"Map to listing"},on:{"click":function($event){$event.stopPropagation();return _vm.onMapToListing(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$map")])],1):_vm._e(),(!item.isMapped && !item.isRemoved)?_c('v-btn',{attrs:{"icon":"","small":"","title":"Ignore"},on:{"click":function($event){$event.stopPropagation();return _vm.onIgnore(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$ignore")])],1):_vm._e()],_c('v-btn',{attrs:{"icon":"","small":"","title":"History"},on:{"click":function($event){$event.stopPropagation();_vm.sourceDataHistoryRecord = item}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$history")])],1)],2)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-4",attrs:{"colspan":headers.length}},[_c('SourceDataDetails',{attrs:{"item":item},on:{"unmap-release":_vm.onUnmapRelease}})],1)]}}],null,true)}),_c('CreateListingDialog',{on:{"cancel":function($event){_vm.createNewListingRecord = null},"created":_vm.onCreateListingConfirmed},model:{value:(_vm.createNewListingRecord),callback:function ($$v) {_vm.createNewListingRecord=$$v},expression:"createNewListingRecord"}}),_c('MapSourceDataDialog',{attrs:{"loading":_vm.loading},on:{"create-listing":function($event){return _vm.onCreateListing(_vm.mappingRecord)},"cancel":function($event){_vm.mappingRecord = null},"submit":_vm.onMapToListingConfirmed},model:{value:(_vm.mappingRecord),callback:function ($$v) {_vm.mappingRecord=$$v},expression:"mappingRecord"}}),_c('IgnoreSourceDataDialog',{on:{"submit":_vm.onIgnoreConfirmed},model:{value:(_vm.ignoringRecord),callback:function ($$v) {_vm.ignoringRecord=$$v},expression:"ignoringRecord"}}),_c('ActivateSourceDataDialog',{attrs:{"show":_vm.activatingRecord !== null},on:{"activated":_vm.activationConfirmed,"close":function($event){_vm.activatingRecord = null}},model:{value:(_vm.activatingRecord),callback:function ($$v) {_vm.activatingRecord=$$v},expression:"activatingRecord"}}),_c('SourceDataHistoryDialog',{attrs:{"value":_vm.sourceDataHistoryRecord},on:{"submit":function($event){_vm.sourceDataHistoryRecord = null}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }