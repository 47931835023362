import { RawLocation } from 'vue-router'
import ViewModel from '@/models/ViewModel'

interface IFilterOptions {
  filter?: string,
  route?: RawLocation,
  active?: boolean,
  icon?: string
  iconColor?: string
}

export default class FilterModel extends ViewModel implements IFilterOptions {
  name: string
  count = 0
  filter: string
  route: RawLocation
  active = false
  icon: string
  iconColor: string
  constructor(name: string, options?: IFilterOptions) {
    super()
    this.name = name
    Object.assign(this, options)
  }
}
