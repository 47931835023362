
























import Vue from 'vue'
import ISourceDataItem from '../models/ISourceDataItem'

export default Vue.extend({
  props: {
    value: Object as () => ISourceDataItem
  },
  data() {
    return {
      comment: null as string
    }
  },
  computed: {
    message(): string {
      return `Are you sure you want to ignore '${this.value?.displayName}'?`
    }
  },
  methods: {
    close() {
      this.comment = null
      this.$emit('input')
    },
    submit() {
      this.$emit('submit', this.comment)
      this.close()
    }
  }
})
